import type { ContractAddresses } from '~/types/contractAddresses';
import type { RecipeProduct } from '~/types/crafting';
import type { AvailableForClaim, ContractClaim, TotalEarned } from '~/types/referral';

type MappedType = AvailableForClaim | TotalEarned;

export function getNftValueByAddress(contracts: ContractAddresses, product: RecipeProduct): string {
  let spConfig = null;

  const { tokenAddress, nftValueMin, nftValueMax } = product;

  if (typeof nftValueMin !== 'number' || typeof nftValueMax !== 'number') return '';

  for (const key in contracts) {
    const configValue = contracts[key as keyof ContractAddresses];

    if (typeof configValue === 'object' && configValue.addresses && configValue.addresses.contract === tokenAddress) {
      spConfig = configValue.addresses.sp;
    }
  }

  if (!spConfig) return '';

  for (const value in spConfig) {
    if (
      spConfig[value as keyof typeof spConfig] >= nftValueMin &&
      spConfig[value as keyof typeof spConfig] <= nftValueMax
    )
      return value;
  }

  return '';
}

export function getTokenAddresses(config: ContractAddresses): string[] {
  const tokenAddressList = [];

  for (const key in config) {
    const configValue = config[key as keyof ContractAddresses];

    if (typeof configValue === 'object' && 'addresses' in configValue) {
      if (configValue.addresses.contract === '') continue;

      tokenAddressList.push(configValue.addresses.contract.toLowerCase());
    } else if (typeof configValue === 'string') {
      tokenAddressList.push(configValue);
    }
  }

  return tokenAddressList;
}

export function mapTokenAddressToName(tokenAddress: string, config: ContractAddresses): string | null {
  for (const key in config) {
    const configValue = config[key as keyof ContractAddresses];

    if (typeof configValue === 'object' && 'addresses' in configValue) {
      if (configValue.addresses.contract.toLowerCase() === tokenAddress.toLowerCase()) {
        return key;
      }
    } else if (configValue.toLowerCase() === tokenAddress.toLowerCase()) {
      return key;
    }
  }
  return null;
}

export function mapTokenAddressesToNames(tokenArray: ContractClaim[], config: ContractAddresses) {
  return tokenArray.map((item) => {
    let tokenName: string | null = null;

    for (const key in config) {
      const configValue = config[key as keyof ContractAddresses];

      if (typeof configValue === 'string') {
        if (configValue.toLowerCase() === item.address.toLowerCase()) {
          tokenName = key;
          break;
        }
      } else if (
        typeof configValue === 'object' &&
        configValue.addresses.contract.toLowerCase() === item.address.toLowerCase()
      ) {
        tokenName = key;
        break;
      }
    }

    return {
      tokenAddress: item.address,
      token: tokenName || 'Unknown',
      value: item.amount
    };
  });
}

export function createMappedArray<T extends { tokenAddress: string }>(
  dataArray: T[],
  config: ContractAddresses
): Array<MappedType & { token: string }> {
  return dataArray.map((item) => {
    const tokenName = mapTokenAddressToName(item.tokenAddress, config);

    if ('totalEarned' in item) {
      return {
        ...item,
        token: tokenName || ''
      } as TotalEarned & { token: string };
    } else {
      throw new Error('Unknown item type');
    }
  });
}
